
.pear-header {
  display: flex; 
  justify-content: space-around;
  align-items: center;
  background-color: #222;
}

.header-left {
  padding: 10px 0
}

.header-center {
  position: relative;
}

.header-center ul li {
  position: relative;
  float: left;
}

.header-center-link {
  font-size: 1.05rem;
  display: block;
  color: #fff;
  padding: 0 24px;
  line-height: 73px;
}

.header-center ul li a:hover {
  color: #a68540;
}

.header-hide {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 73px;
  background-color: #222;
  z-index: 9999;
  width: 120px;
  padding-left: 20px;
  overflow: hidden;
}

.header-center ul li:hover div {
  opacity: 1;
  visibility: visible;
}

.header-hide ul li {
  width: 100%;
}

.header-hide ul li a {
  font-size: 1rem;
  color: #fff;
  line-height: 45px;
}

.header-right ul li {
  float: left;
}

.header-right ul li a {
  display: block;
  color: #fff;
  padding: 0 30px;
  line-height: 73px;
}

.header-contact {
  background-color: #a68540;
}