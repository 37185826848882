.ant-layout {
  background: #fff;
}

.ant-menu {
  height: 100%;
}

.content-body {
  margin: 3% 40% 0 5%;
  min-height: 999px;
  line-height: 28px;
}

.content-body h1 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 30px;
}

.content-body h2 {
  font-size: 15px;
  font-weight: 550;
}

.content-body p {
  margin-bottom: 10px;
  font-size: 15px;
}

.content-body img {
  width: 100%;
  margin: 15px 0;
}

.content-body ul {
  list-style-type: disc;
  margin-left: 24px;
}