.footer {
  background-color: #3d3e49;
  line-height: 1.875rem;
  padding: 0rem 1.5rem 1.875rem;
}

.footer .footer-container {
  margin: 0 auto;
  max-width: 1200px;
  width: 90%;
}

.footer .footer-container .footer-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-bottom: calc(3rem - 0.75rem)
}

.footer .footer-container .footer-body .footer-item {
  width: 16.66%;
}

.footer-item-menu {
  padding: 0.75rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.footer-item-menu h3 {
  margin: 0 0 1.25rem 0;
  font-size: 1rem;
  font-weight: 500;
  text-align: left;
  color: #ffffff;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.footer-item-menu ul {
  margin-left: 0;
  display: flex;
  flex-flow: column wrap;
}

.footer-item-menu ul li a {
  font-size: 14px;
  text-align: left;
  opacity: 0.5;
  color: #fff;
  padding-left: 0;
  line-height: 73px;
}

.footer-item-menu ul li a:hover {
  opacity: 1;
}

.footer-item-logo {
  width: 400px;
  padding: 0.75rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.footer-item-logo figure {
  text-align: left;
  margin: 0 0 1.125rem 0;
  position: relative;
}

.footer-item-logo figure img {
  height: 100px;
  max-width: 100%;
}

.footer-item-logo figure h3 {
  color: #a68540;
  position: absolute;
  left: 26px;
  font-size: 1.5rem;
}

.footer-item-logo div ul {
  margin-left: 0;
  display: flex;
  flex-flow: row wrap;
  margin-left: 32px;
  justify-content: space-around;
}

.footer-item-logo div ul li span {
  font-size: 14px;
  text-align: left;
  opacity: 0.5;
  color: #fff;
  padding-left: 0;
}

.footer-item-logo div ul li a {
  font-size: 14px;
  text-align: left;
  opacity: 0.5;
  color: #fff;
  padding-left: 0;
  display: block;
  line-height: 4rem;
}

.footer-item-logo div ul li a:hover {
  opacity: 1;
}

.footer-words {
  text-align: center;
  color: #fff;
  opacity: 0.5;
}

.footer-words a {
  color: #fff;
}

.footer-words a:hover {
  color: #a68540;
  text-decoration: none;
  opacity: 1;
}

.footer-address {
  display: flex;
  justify-content: center;
  color: #fff;
  opacity: 0.5;
}

.wechat-block {
  position: relative;
}

.wechat-block a:hover {
  opacity: 1;
}

.wechat-block a:hover +.wechat-hide {
  visibility: visible;
  opacity: 1;
}

.wechat-hide {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  padding: 10px 10px 0;
  text-align: center;
  left: -40px;
  top: 50px;
  z-index: 100;
  color: #68747f;
  background-color: #fff;
  border-radius: 3px;
  line-height: 1.5;
  width: 100px;
}

.wechat-hide:hover {
  visibility: visible;
  opacity: 1;
}

.wechat-hide figure {
  margin: 0;
  padding: 0;
}

.wechat-hide::after {
  content: '';
  left: -6px;
  position: absolute;
  top: 77px;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-style: solid;
  border-width: 6px;
  border-color: transparent;
  /*border-right: 6px solid #fff;*/
  border-left-width: 0;
}

.address-block {
  position: relative;
}

.address-block a:hover {
  opacity: 1;
}

.address-block a:hover +.address-hide {
  visibility: visible;
  opacity: 1;
}

.address-hide {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  padding: 10px;
  width: 200px;
  text-align: left;
  left: -80px;
  top: 50px;
  z-index: 100;
  color: #68747f;
  background-color: #fff;
  border-radius: 3px;
  line-height: 1.5;
}

.address-hide:hover {
  visibility: visible;
  opacity: 1;
}

.address-hide::after {
  content: '';
  left: -6px;
  position: absolute;
  top: 77px;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-style: solid;
  border-width: 6px;
  border-color: transparent;
  border-right: 0px solid #fff;
  border-left-width: 0;
}

.zhihu-block {
  position: relative;
}

.zhihu-block a:hover {
  opacity: 1;
}

.zhihu-block a:hover +.zhihu-hide {
  opacity: 1;
  visibility: visible;
}

.zhihu-hide {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  padding: 10px;
  text-align: left;
  left: 40px;
  bottom: 0;
  z-index: 100;
  color: #68747f;
  background-color: #fff;
  border-radius: 3px;
  line-height: 1.5;
}

.zhihu-hide:hover {
  opacity: 1;
  visibility: visible;
}

.zhihu-hide::after {
  content: '';
  left: -6px;
  position: absolute;
  top: 20px;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-style: solid;
  border-width: 6px;
  border-color: transparent;
  border-right: 6px solid #fff;
  border-left-width: 0;
}

.mail-block {
  position: relative;
}

.mail-block a:hover {
  opacity: 1;
}

.mail-block a:hover +.mail-hide {
  opacity: 1;
  visibility: visible;
}

.mail-hide {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  padding: 10px;
  text-align: left;
  left: -40px;
  top: 50px;
  z-index: 100;
  color: #68747f;
  background-color: #fff;
  border-radius: 3px;
  line-height: 1.5;
}

.mail-hide:hover {
  opacity: 1;
  visibility: visible;
}

.mail-hide::after {
  content: '';
  left: -6px;
  position: absolute;
  top: 20px;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-style: solid;
  border-width: 6px;
  border-color: transparent;
  border-right: 0px solid #fff;
  border-left-width: 0;
}

.phone-block {
  position: relative;
}

.phone-block a:hover {
  opacity: 1;
}

.phone-block a:hover +.phone-hide {
  opacity: 1;
  visibility: visible;
}

.phone-hide {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  padding: 10px;
  width: 170px;
  text-align: left;
  left: 40px;
  bottom: -20px;
  z-index: 100;
  color: #68747f;
  background-color: #fff;
  border-radius: 3px;
  line-height: 1.5;
}

.phone-hide:hover {
  opacity: 1;
  visibility: visible;
}

.phone-hide::after {
  content: '';
  left: -6px;
  position: absolute;
  top: 20px;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-style: solid;
  border-width: 6px;
  border-color: transparent;
  border-right: 6px solid #fff;
  border-left-width: 0;
}



.jyh-block {
  position: relative;
}

.jyh-block a:hover {
  opacity: 1;
}

.jyh-block:hover div {
  opacity: 1;
  visibility: visible;
}

.jyh-hide {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  padding: 10px;
  width: 170px;
  text-align: left;
  left: 55px;
  bottom: -5px;
  z-index: 100;
  color: #68747f;
  background-color: #fff;
  border-radius: 3px;
  line-height: 1.5;
}

.jyh-hide:hover {
  opacity: 1;
  visibility: visible;
}

.jyh-hide::after {
  content: '';
  left: -6px;
  position: absolute;
  top: 42px;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border-style: solid;
  border-width: 6px;
  border-color: transparent;
  border-right: 6px solid #fff;
  border-left-width: 0;
}